<template>
  <b-form-select v-bind:class="cssClass" :style="cssStyle" aria-placeholder="年" v-model="yearInputLocal">
    <template v-slot:first>
      <b-form-select-option :value="null">年を選択してください。</b-form-select-option>
    </template>
    <b-form-select-option v-for="year in yearRange" v-bind:key="year" :value="year">{{year}}</b-form-select-option>
  </b-form-select>
</template>

<script>
export default {
  props: ["css-class", "css-style", "year-input"],
  model:{
    prop:"year-input",
    event: "change"
  },
  computed: {
    yearRange: function(){
      let currentYear = new Date().getFullYear();
      let yearRange = [];
      for(let i = currentYear; i > currentYear - 100; i--){
        yearRange.push(i);
      }
      return yearRange;
    },
    yearInputLocal: {
      get: function() {
        return this.yearInput;
      },
      set: function(value) {
        this.$emit('change', value);
      }
    }
  }
}
</script>

<style>

</style>