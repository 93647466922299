<template>
  <div>
    <ul v-if="!isRegistrationWizard" class="biz-breadcrumb">
      <li><router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link></li>
      <li><router-link to="/supplier/manage/company-info">{{$t('COMPANY_INFO.TITLE')}}</router-link></li>
      <li><a>{{$t('COMPANY_INFO.HEADER_ADDITIONAL_INFO') + '(' + $t('COMMON.EDIT') + ')'}}</a></li>
    </ul>
    <div class="page">
      <div class="page-title" v-if="!isRegistrationWizard" >
        <h1>{{$t('COMPANY_INFO.TITLE')}}</h1>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <b-form @submit.prevent="handleSubmit(onSave)">
          <!-- <CompanyBasicView :company="company" :previewMode="false"/> -->
          <div class="mt-4 card biz-primary">
            <div class="card-header">
              {{$t('COMPANY_INFO.HEADER_ADDITIONAL_INFO')}}
              <p>{{$t('COMPANY_INFO.ADDITIONAL_INFO_DESC')}}</p>
            </div>
            <div class="card-body">
              <div class="biz-form-group seperated">
                <label class="biz-form-label required">{{$t('COMPANY_INFO.ESTABLISHMENT_DATE')}}</label>
                <div class="biz-form-body">
                  <div class="row">
                    <div class="col-auto">
                      <b-form-group class="biz-input-group form-inline">
                        <div class="row">
                          <div class="ml-3">
                          <validation-provider :name="$t('COMPANY_INFO.ESTABLISHMENT_DATE')+'('+$t('COMMON.YEAR')+')'" rules="required|numeric|numericYear" v-slot="{ errors }">
                            <b-form-input 
                              class="text-right form-control"
                              type="text" placeholder=2000
                              v-on:change="initFinancialData"
                              v-model="company.summary.establishmentDateYear"/>
                            <span class="unit ml-2">{{$t('COMMON.YEAR')}}</span>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </validation-provider>
                          </div>
                          <div class="mr-3">
                          <validation-provider :name="$t('COMPANY_INFO.ESTABLISHMENT_DATE')+'('+$t('COMMON.MONTH')+')'" rules="required" v-slot="{ errors }">
                            <b-form-select class="d-inline-block ml-2" style="width: 80px;" :aria-placeholder="$t('COMMON.MONTH')" v-model="company.summary.establishmentDateMonth" v-on:change="initFinancialData">
                              <template v-slot:first>
                                <b-form-select-option :value="null">{{$t('COMMON.MONTH_PLACEHOLDER')}}</b-form-select-option>
                              </template>
                            <b-form-select-option v-for="i in 12" v-bind:key="i" :value="i">{{i}}</b-form-select-option>
                            </b-form-select>
                            <span class="unit ml-2">{{$t('COMMON.MONTH')}}</span>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </validation-provider>
                          </div>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="biz-form-group seperated mt-3">
                <label class="biz-form-label required">{{$t('COMPANY_INFO.CAPITAL')}}</label>
                <div class="biz-form-body">
                  <div class="row">
                    <div class="col-auto">
                      <b-form-group class="biz-input-group form-inline">
                        <validation-provider :name="$t('COMPANY_INFO.CAPITAL')" rules="required|numeric|min_value:1" v-slot="{ errors }">
                          <InputComma type="text" maxlength="15" :placeholder="$t('COMPANY_INFO.CAPITAL_PLACEHOLDER')" v-model="company.summary.capital"/>
                          <span class="unit ml-2">円</span>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>

              <div class="biz-form-group seperated mt-3">
                <label class="biz-form-label required">{{$t('COMPANY_INFO.EMPLOYEES_COUNT')}}</label>
                <div class="biz-form-body">
                  <div class="row">
                    <div class="col-auto">
                      <b-form-group class="biz-input-group form-inline">
                        <validation-provider :name="$t('COMPANY_INFO.EMPLOYEES_COUNT')" rules="required|numeric|min_value:1" v-slot="{ errors }">
                          <InputComma type="text" :placeholder="$t('COMPANY_INFO.EMPLOYEES_COUNT_PLACEHOLDER')" v-model="company.summary.employeesNumber"/>
                          <span class="unit ml-2">名</span>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>

              <div class="biz-form-group seperated mt-3">
                <label class="biz-form-label required">{{$t('COMPANY_INFO.COMPANY_NAME_KATA')}}</label>
                <div class="biz-form-body">
                  <div class="row">
                    <div class="col-3">
                      <b-form-group class="biz-input-group" :label="$t('COMPANY_INFO.COMPANY_NAME_KANJI')">
                        <validation-provider :name="$t('COMPANY_INFO.COMPANY_NAME_KANJI')" rules="required" v-slot="{ errors }">
                          <b-form-input 
                            type="text" :placeholder="$t('COMPANY_INFO.COMPANY_NAME_KANJI_PLACEHOLDER')" 
                            v-model="company.summary.ceoName"/>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-3">
                      <b-form-group class="biz-input-group" :label="$t('COMPANY_INFO.COMPANY_NAME_FULL_WIDTH')">
                        <validation-provider :name="$t('COMPANY_INFO.COMPANY_NAME_FULL_WIDTH')" :rules="{required:true, katakanaOnly: true}" v-slot="{ errors }">
                          <b-form-input type="text" 
                            :placeholder="$t('COMPANY_INFO.COMPANY_NAME_FULL_WIDTH_PLACEHOLDER')"
                            v-b-popover.focus.top="$t('COMMON.NAME_KANA_DESC')" 
                            v-model="company.summary.ceoNameKana"></b-form-input>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <!-- <div class="col-6">
                      <b-form-group class="biz-input-group" :label="$t('COMMON.BIRTH_DATE')">
                        <div class="row">
                          <div class="ml-3">
                            <validation-provider :name="$t('COMMON.YEAR')" rules="required" v-slot="{ errors }">
                              <year-select css-class="d-inline-block" css-style="width: 100px;" v-model="company.summary.ceoBirthYear"/>
                              <span class="unit ml-2">{{$t('COMMON.YEAR')}}</span>                          
                              <span class="invalid-feedback">{{ errors[0] }}</span>
                            </validation-provider>
                          </div>
                          
                          <div class="ml-3">
                            <validation-provider :name="$t('COMMON.MONTH')" rules="required" v-slot="{ errors }">
                              <b-form-select class="d-inline-block ml-2" style="width: 80px;" :aria-placeholder="$t('COMMON.MONTH')" v-model="company.summary.ceoBirthMonth">
                                <template v-slot:first>
                                  <b-form-select-option :value="null">{{$t('COMMON.MONTH_PLACEHOLDER')}}</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="i in 12" v-bind:key="i" :value="i - 1">{{i}}</b-form-select-option>
                              </b-form-select><span class="unit ml-2">{{$t('COMMON.MONTH')}}</span>
                              <span class="invalid-feedback">{{ errors[0] }}</span>
                            </validation-provider>
                          </div>
                          
                          <div class="ml-3">
                            <validation-provider :name="$t('COMMON.DAY_OF_MONTH')" rules="required" v-slot="{ errors }">
                              <b-form-select class="d-inline-block ml-2" style="width: 80px;" :aria-placeholder="$t('COMMON.DAY_OF_MONTH')" v-model="company.summary.ceoBirthDay">
                                <template v-slot:first>
                                  <b-form-select-option :value="null">{{$t('COMMON.DAY_PLACEHOLDER')}}</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="i in 31" v-bind:key="i" :value="i">{{i}}</b-form-select-option>
                              </b-form-select><span class="unit ml-2">{{$t('COMMON.DAY')}}</span>
                              <span class="invalid-feedback">{{ errors[0] }}</span>
                            </validation-provider>
                          </div>
                        </div>
                      </b-form-group>
                    </div> -->
                  </div>
                </div>
              </div>

              <div class="biz-form-group seperated mt-3">
                <label class="biz-form-label optional">{{$t('COMPANY_INFO.DIRECTOR_OTHER_THAN_CEO')}}</label>
                <div class="biz-form-body">
                  <div class="row" v-for="(executive, index) in company.executives" v-bind:key="index">
                    <div class="col-3">
                      <b-form-group class="biz-input-group" :label="$t('COMMON.NAME_KANJI')">
                        <b-form-input :placeholder="$t('COMMON.NAME_KANJI_PLACEHOLDER_2')" v-model="executive.name"/>
                      </b-form-group>
                    </div>
                    <div class="col-3">
                      <b-form-group class="biz-input-group" :label="$t('COMMON.NAME_KANA')">
                        <b-form-input :placeholder="$t('COMMON.NAME_KANA_PLACEHOLDER_2')" v-b-popover.focus.top="$t('COMMON.NAME_KANA_DESC')"  v-model="executive.nameKana"/>
                      </b-form-group>
                    </div>
                    <div class="col-6">
                      <b-form-group class="biz-input-group d-inline-block" style="vertical-align:middle;" :label="$t('COMMON.BIRTH_DATE')">
                      <year-select css-class="d-inline-block" css-style="width: 100px;" v-model="executive.birthYear"/>
                        <span class="unit ml-2">{{$t('COMMON.YEAR')}}</span>
                        <b-form-select class="d-inline-block ml-2" style="width: 80px;" :aria-placeholder="$t('COMMON.MONTH')" v-model="executive.birthMonth">
                          <template v-slot:first>
                            <b-form-select-option :value="null">{{$t('COMMON.MONTH_PLACEHOLDER')}}</b-form-select-option>
                          </template>
                          <b-form-select-option v-for="i in 12" v-bind:key="i" :value="i">{{i}}</b-form-select-option>
                        </b-form-select>
                        <span class="unit ml-2">{{$t('COMMON.MONTH')}}</span>
                        <b-form-select class="d-inline-block ml-2" style="width: 80px;" :aria-placeholder="$t('COMMON.DAY')" v-model="executive.birthDay">
                            <template v-slot:first>
                              <b-form-select-option :value="null">{{$t('COMMON.DAY_PLACEHOLDER')}}</b-form-select-option>
                            </template>
                            <b-form-select-option v-for="i in 31" v-bind:key="i" :value="i">{{i}}</b-form-select-option>
                          </b-form-select>
                          <span class="unit ml-2">{{$t('COMMON.DAY')}}</span>
                      </b-form-group>
                      <div class="d-inline-block">
                        <a class="ml-3" href="javascript:void(0);" v-on:click="onAddProperty(company.executives, { birthYear: null, birthMonth: null, birthDay: null })">
                          <font-awesome-icon icon="plus"></font-awesome-icon> {{$t('COMMON.ADD')}}
                        </a>
                        <a href="javascript:void(0);" class="ml-2" v-on:click="onRemoveProperty(index, company.executives)" v-if="index > 0  || executive.code">
                          <font-awesome-icon icon="minus"></font-awesome-icon> {{$t('COMMON.REMOVE')}}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="biz-form-group seperated mt-3">
                <label class="biz-form-label optional">{{$t('COMPANY_INFO.WEBSITE')}}</label>
                <div class="biz-form-body">
                  <div class="row">
                    <div class="col-5">
                      <b-form-group class="biz-input-group">
                        <b-form-input type="text" :placeholder="$t('COMPANY_INFO.WEBSITE_PLACEHOLDER')" v-model="company.summary.url">
                        </b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>

              <div class="biz-form-group mt-3 seperated">
                <label class="biz-form-label optional">{{$t('COMPANY_INFO.FACTORY')}}</label>
                <div class="biz-form-body">
                  <div class="row" v-for="(factory, index) in company.factories" v-bind:key="factory.id">
                    <div class="col-3">
                      <b-form-group class="biz-input-group">
                        <b-form-input :placeholder="$t('COMPANY_INFO.FACTORY_PLACEHOLDER')" v-model="factory.name"/>
                      </b-form-group>
                    </div>
                    <div class="col-4">
                      <b-form-group class="biz-input-group">
                        <b-form-input :placeholder="$t('COMMON.ADDRESS_PLACEHOLDER')" v-model="factory.address"/>
                      </b-form-group>
                    </div>
                    <div class="col biz-offset">
                      <a href="javascript:void(0);" v-on:click="onAddProperty(company.factories)">
                        <font-awesome-icon icon="plus"></font-awesome-icon> {{$t('COMMON.ADD')}}
                      </a>
                      <a href="javascript:void(0);" class="ml-2" v-if="index > 0" v-on:click="onRemoveProperty(index, company.factories)">
                        <font-awesome-icon icon="minus"></font-awesome-icon> {{$t('COMMON.REMOVE')}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="biz-form-group mt-3 seperated">
                <label class="biz-form-label optional">{{$t('COMPANY_INFO.OFFICES_OTHER_THAN_FACTORY_AND_MAIN_ADDRESS')}}</label>
                <div class="biz-form-body">
                  <div class="row" v-for="(office, index) in company.offices" v-bind:key="office.id">
                    <div class="col-3">
                      <b-form-group class="biz-input-group">
                        <b-form-input :placeholder="$t('COMPANY_INFO.OFFICE_PLACEHOLDER')" v-model="office.name"/> 
                      </b-form-group>
                    </div>
                    <div class="col-4">
                      <b-form-group class="biz-input-group">
                        <b-form-input :placeholder="$t('COMMON.ADDRESS_PLACEHOLDER')" v-model="office.address"/>
                      </b-form-group>
                    </div>
                    <div class="col biz-offset">
                      <a href="javascript:void(0);" v-on:click="onAddProperty(company.offices)">
                        <font-awesome-icon icon="plus"></font-awesome-icon> {{$t('COMMON.ADD')}}
                      </a>
                      <a href="javascript:void(0);" class="ml-2" v-if="index > 0" v-on:click="onRemoveProperty(index, company.offices)">
                        <font-awesome-icon icon="minus"></font-awesome-icon> {{$t('COMMON.REMOVE')}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="biz-form-group mt-3 seperated">
                <label class="biz-form-label optional">{{$t('COMPANY_INFO.MAIN_SALES')}}</label>
                <div class="biz-form-body">
                  <div class="row" v-for="(sales,index) in company.sellers" v-bind:key="sales.id">
                    <div class="col-3">
                      <b-form-group class="biz-input-group">
                        <b-form-input :placeholder="$t('COMPANY_INFO.MAIN_SALES_PLACEHOLDER')" v-model="sales.name"/>
                      </b-form-group>
                    </div>
                    <div class="col biz-offset">
                      <a href="javascript:void(0);" v-on:click="onAddProperty(company.sellers)">
                        <font-awesome-icon icon="plus"></font-awesome-icon> {{$t('COMMON.ADD')}}
                      </a>
                      <a href="javascript:void(0);" class="ml-2" v-if="index > 0" v-on:click="onRemoveProperty(index, company.sellers)">
                        <font-awesome-icon icon="minus"></font-awesome-icon> {{$t('COMMON.REMOVE')}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="biz-form-group mt-3 seperated">
                <label class="biz-form-label optional">{{$t('COMPANY_INFO.MAIN_SUPPLIERS')}}</label>
                <div class="biz-form-body">
                  <div class="row" v-for="(supplier, index) in company.suppliers" v-bind:key="supplier.id">
                    <div class="col-3">
                      <b-form-group class="biz-input-group">
                        <b-form-input :placeholder="$t('COMPANY_INFO.MAIN_SUPPLIERS_PLACEHOLDER')" v-model="supplier.name"/>
                      </b-form-group>
                    </div>
                    <div class="col biz-offset">
                      <a href="javascript:void(0);" v-on:click="onAddProperty(company.suppliers)">
                        <font-awesome-icon icon="plus"></font-awesome-icon> {{$t('COMMON.ADD')}}
                      </a>
                      <a href="javascript:void(0);" class="ml-2" v-if="index > 0" v-on:click="onRemoveProperty(index, company.suppliers)">
                        <font-awesome-icon icon="minus"></font-awesome-icon> {{$t('COMMON.REMOVE')}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="biz-form-group mt-3 seperated">
                <label class="biz-form-label optional">{{$t('COMPANY_INFO.MAIN_SHAREHOLDERS')}}</label>
                <div class="biz-form-body">
                  <div class="row" v-for="(shareholder, index) in company.shareholders" v-bind:key="shareholder.id">
                    <div class="col-3">
                      <b-form-group class="biz-input-group">
                        <b-form-input :placeholder="$t('COMPANY_INFO.MAIN_SHAREHOLDERS_PLACEHOLDER')" v-model="shareholder.name"/>
                      </b-form-group>
                    </div>
                    <div class="col biz-offset">
                      <a href="javascript:void(0);" v-on:click="onAddProperty(company.shareholders)">
                        <font-awesome-icon icon="plus"></font-awesome-icon> {{$t('COMMON.ADD')}}
                      </a>
                      <a href="javascript:void(0);" class="ml-2" v-if="index > 0" v-on:click="onRemoveProperty(index, company.shareholders)">
                        <font-awesome-icon icon="minus"></font-awesome-icon> {{$t('COMMON.REMOVE')}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

               <div class="biz-form-group mt-3 seperated">
                <label class="biz-form-label optional">{{$t('COMPANY_INFO.BANKS')}}</label>
                <div class="biz-form-body">
                  <div class="row" v-for="(bank, index) in company.banks" v-bind:key="bank.id">
                    <div class="col-3">
                      <b-form-group class="biz-input-group">
                        <b-form-input :placeholder="$t('COMPANY_INFO.BANKS_PLACEHOLDER')" v-model="bank.name"/>
                      </b-form-group>
                    </div>
                    <div class="col biz-offset">
                      <a href="javascript:void(0);" v-on:click="onAddProperty(company.banks)">
                        <font-awesome-icon icon="plus"></font-awesome-icon> {{$t('COMMON.ADD')}}
                      </a>
                      <a href="javascript:void(0);" class="ml-2" v-if="index > 0" v-on:click="onRemoveProperty(index, company.banks)">
                        <font-awesome-icon icon="minus"></font-awesome-icon> {{$t('COMMON.REMOVE')}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="biz-form-group mt-3">
                <label class="biz-form-label optional">{{$t('COMPANY_INFO.MAKERS')}}</label>
                <div class="biz-form-body">
                  <div class="row" v-for="(manufacturer, index) in company.manufacturers" v-bind:key="manufacturer.id">
                    <div class="col-3">
                      <b-form-group class="biz-input-group">
                        <b-form-input :placeholder="$t('COMPANY_INFO.MAKERS_PLACEHOLDER')" v-model="manufacturer.name"/>
                      </b-form-group>
                    </div>
                    <div class="col biz-offset">
                      <a href="javascript:void(0);" v-on:click="onAddProperty(company.manufacturers)">
                        <font-awesome-icon icon="plus"></font-awesome-icon> {{$t('COMMON.ADD')}}
                      </a>
                      <a href="javascript:void(0);" class="ml-2" v-if="index > 0" v-on:click="onRemoveProperty(index, company.manufacturers)">
                        <font-awesome-icon icon="minus"></font-awesome-icon> {{$t('COMMON.REMOVE')}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4 card biz-primary">
            <div class="card-header" id="financialInfo">
              {{$t('COMPANY_INFO.FINANCIAL_INFO')}}
              <p>{{$t('COMPANY_INFO.FINANCIAL_INFO_DESC')}}</p>
            </div>
            <div class="card-body">
              <div class="al-child-mid">
                <div class="pt-2 pb-2  text-muted" style="flex: 1;">
                  <!--3年以内に設立したため過去3年分の決算情報がない場合は、該当の期を削除ボタンから削除してください。-->
                </div>
                <div class="control pt-2 pb-2 ml-3 ">
                  <a href="javascript:void();" v-if="!isRegistrationWizard" @click.prevent="onAddFinancialInfo()">
                    <font-awesome-icon icon="plus"></font-awesome-icon>
                    {{$t('COMPANY_INFO.FINANCIAL_INFO_ADD')}}
                  </a>
                </div>
              </div>
              <div class="row seperated pt-3 pl-2" style="flex-flow:nowrap;" v-for="(financialInfo, i) in company.financialInfos" v-bind:key="i">
                <div style="width: 200px;">
                  <div :class="'only-biz-form-label ' + (isFinancialRequired(i) ? 'required':'optional')">
                    {{getNthTime(i)}}
                  </div>
                </div>
                <div class="col">
                  <b-form-group 
                    :label="$t('COMPANY_INFO.SETTLEMENT_DATE')" class="biz-input-group">
                    <div class="d-flex">
                      <validation-provider :name="$t('COMMON.YEAR')" :rules="{required:isFinancialRequired(i), yearOnly:true}" v-slot="{ errors }">
                        <span style="display:flex;align-items:center;">
                          <b-form-input style="flex:1" type="number" :placeholder="$t('COMPANY_INFO.SETTLEMENT_DATE_YEAR_PLACEHOLDER')" v-model="financialInfo.year"/>
                          <span class="mx-1">{{$t('COMMON.YEAR')}}</span>
                        </span>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </validation-provider>
                      <validation-provider :name="$t('COMMON.MONTH')" :rules="{required:isFinancialRequired(i)}" v-slot="{ errors }">
                        <b-form-select v-model="financialInfo.month">
                          <template v-slot:first>
                            <b-form-select-option :value="null">{{$t('COMMON.MONTH_PLACEHOLDER')}}</b-form-select-option>
                          </template>
                          <b-form-select-option v-for="i in 12" v-bind:key="i" :value="i">{{i}}{{$t('COMMON.MONTH')}}</b-form-select-option>
                        </b-form-select>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </b-form-group>
                </div>
                <div class="col">
                    <b-form-group :label="$t('COMPANY_INFO.SALES')" class="biz-input-group form-inline mb-1">
                      <validation-provider :name="$t('COMPANY_INFO.SALES')" :rules="{required:isFinancialRequired(i), numericFinancial:true}" mode="eager" v-slot="{ errors }">
                        <div class="d-flex" style="align-items:center;">
                          <InputComma type="text" style="flex:1;width: 15%;" maxlength="15" :placeholder="$t('COMPANY_INFO.SALES_PLACEHOLDER')" allowedMinus v-model="financialInfo.sales"/>
                          <span class="unit ml-2">{{$t('COMMON.YEN_MARK')}}</span>
                        </div>
                        <span class="invalid-feedback mb-2">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                    
                </div>
                <div class="col">
                    <b-form-group :label="$t('COMPANY_INFO.PROFIT_BEFORE_TAX')" class="biz-input-group form-inline mb-1">
                      <validation-provider :name="$t('COMPANY_INFO.PROFIT_BEFORE_TAX')" :rules="{required:isFinancialRequired(i),numericFinancial:true}" mode="eager" v-slot="{ errors }">
                        <div class="d-flex" style="align-items:center;">
                          <InputComma type="text" style="flex:1;width: 15%;" maxlength="15" :placeholder="$t('COMPANY_INFO.PROFIT_BEFORE_TAX_PLACEHOLDER')" allowedMinus v-model="financialInfo.ordinaryProfit"/>
                          <span class="unit ml-2">{{$t('COMMON.YEN_MARK')}}</span>
                        </div>
                        <span class="invalid-feedback mb-2">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group :label="$t('COMPANY_INFO.PROFIT_AFTER_TAX')" class="biz-input-group form-inline mb-1">
                      <validation-provider :name="$t('COMPANY_INFO.PROFIT_AFTER_TAX')" :rules="{required:isFinancialRequired(i), numericFinancial:true}" mode="eager" v-slot="{ errors }">
                        <div class="d-flex" style="align-items:center;">
                          <InputComma type="text" style="flex:1;width: 15%;" maxlength="15" :placeholder="$t('COMPANY_INFO.PROFIT_AFTER_TAX_PLACEHOLDER')" allowedMinus v-model="financialInfo.profitAfterTax"/>
                          <span class="unit ml-2">{{$t('COMMON.YEN_MARK')}}</span>
                        </div>
                        <span class="invalid-feedback mb-2">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center mt-5">
            <j-button variant="primary" size="lg" buttonType="submit">
              {{isRegistrationWizard?$t('COMMON.NEXT'):$t('COMMON.SAVE')}}
            </j-button>
            <div class="mt-4" v-if="!isRegistrationWizard">
              <j-button variant="light" to="/supplier/manage/company-info">
                <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                {{$t('COMMON.GO_BACK')}}
              </j-button>
            </div>
          </div>
        </b-form>
      </ValidationObserver> 
    </div>
  </div>
</template>

<style scoped lang="scss">
  .input-sized-label {
    height: 38px;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
  }
</style>
<script>
  
  import CompanyInfoModel from '@/model/CompanyInfo';
  import AreaModel from '@/model/Area';

  import YearSelect from '@/components/common/YearSelect.vue';
 import InputComma from '@/components/input/InputComma.vue';


  export default {
    props: ["isRegistrationWizard"],
    components: { YearSelect, /*CompanyBasicView,*/ InputComma },
    data: () => ({
      company: {
        basic:          {},
        summary:        {},
        industries:     [],
        factories:      [],
        offices:        [],
        sellers:        [],
        suppliers:      [],
        shareholders:   [],
        banks:          [],
        manufacturers:  [],
        financialInfos: [],
      }
    }),
    created(){
      Promise.all([
        CompanyInfoModel.getSummary(this.$store.state.user.companyCode),
      ]).then(results => {
        let companyData = results[0].data;
        if(this.formData){ companyData = this.formData; }
        this.company = companyData;
        this.initData();
        this.$refs.form.reset();
        if(this.$route.hash === "#financialInfo"){
          setTimeout(function(){
            document.getElementById("financialInfo").scrollIntoView();
          },200);
        }
      }).catch(reason => this.$errorMessage(undefined, {reason}));
    },

    methods: {
      async onSave(){
        let popupRes = true;
        if(this.$store.state.user && this.$store.state.user.status.registrationCompleted){
          popupRes = await this.$bvModal.msgBoxConfirm(this.$t('COMPANY_INFO.CONFIRM_BOX_APPLY'), {
            okTitle: this.$t('COMMON.UPDATE'),
            cancelTitle: this.$t('COMMON.CANCEL')
          })
        }
        if(popupRes){
          this.cleanData();
          CompanyInfoModel.saveCompanyAddon(this.$store.state.user.companyCode, this.company).then(()=>{
            this.initData();
            if(this.$store.state.user && this.$store.state.user.status.registrationCompleted){
              this.$successMessage(this.$t('COMPANY_INFO.SUCCESS_MESSAGE'));
            }else{
              this.$successMessage(this.$t('COMPANY_INFO.SUCCESS_MESSAGE'));
            }
            if(this.isRegistrationWizard){
              this.$emit('onSave', 'CompanyAddonInfo');
            }else{
              this.$router.push('/supplier/manage/company-info');
            }
            
          }).catch(reason => this.$errorMessage(undefined, {reason}));
        }
        
      },
      onAddProperty(properties, custom = {}){
        if(properties && properties.length >= 10) {
          this.$warningMessage(this.$t('COMMON.WARNING_MAX_10_ITEM'));
          return;
        }
        properties.push(custom);
      },
      onRemoveProperty(index, properties){
        if(index > -1)
          properties.splice(index, 1);
      },
      onRemoveFinancialData(index){
        if(index > -1) 
          this.company.financialInfos.splice(index, 1);
      },
      onIndustrySelect(index, selected){
        this.$set(this.company.industries, index, selected);
      },
      onAutoAddress(){
        AreaModel.getAddress(this.form.postalCode).then(result => {
          this.$set(this.form, "addressAreaCode", result.data.areaCode);
          this.$set(this.form, "address1", result.data.address);
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      },
      onAddFinancialInfo(){
        if(this.company.financialInfos.length <= 0) {
          this.company.financialInfos.unshift({ month: null });
          return;
        }
        if(!this.company.financialInfos[0].year && !this.company.financialInfos[0].month)return;
        this.company.financialInfos.unshift({  month: null });
      },
      getNthTime(i){
        if(i == 0){
          return this.$t('COMMON.IMMEDIATELY_BEFORE');
        }else{
          return (i+1) + this.$t('COMMON.NTH_TIME');
        }
      },
      cleanData(){
        if(this.company.summary){
          this.company.summary.establishmentDateMonth -= 1;
        }
        this.company.executives =     CompanyInfoModel.cleanYakuinData(this.company.executives);
        this.company.industries =     CompanyInfoModel.cleanPropertyData(this.company.industries);
        this.company.industries =     CompanyInfoModel.cleanPropertyData(this.company.industries);
        this.company.factories =      CompanyInfoModel.cleanPropertyData(this.company.factories);
        this.company.offices =        CompanyInfoModel.cleanPropertyData(this.company.offices);
        this.company.sellers =        CompanyInfoModel.cleanPropertyData(this.company.sellers);
        this.company.suppliers =      CompanyInfoModel.cleanPropertyData(this.company.suppliers);
        this.company.shareholders =   CompanyInfoModel.cleanPropertyData(this.company.shareholders);
        this.company.banks =          CompanyInfoModel.cleanPropertyData(this.company.banks);
        this.company.manufacturers =  CompanyInfoModel.cleanPropertyData(this.company.manufacturers);
        this.company.financialInfos = CompanyInfoModel.cleanFinancialInfo(this.company.financialInfos);
      },
      initData(){
        this.company.executives =     CompanyInfoModel.initPropertyInput(this.company.executives, [{birthYear: null, birthMonth: null, birthDay: null}]);
        this.company.industries =     CompanyInfoModel.initPropertyInput(this.company.industries);
        this.company.factories =      CompanyInfoModel.initPropertyInput(this.company.factories);
        this.company.offices =        CompanyInfoModel.initPropertyInput(this.company.offices);
        this.company.sellers =        CompanyInfoModel.initPropertyInput(this.company.sellers);
        this.company.suppliers =      CompanyInfoModel.initPropertyInput(this.company.suppliers);
        this.company.shareholders =   CompanyInfoModel.initPropertyInput(this.company.shareholders);
        this.company.banks =          CompanyInfoModel.initPropertyInput(this.company.banks);
        this.company.manufacturers =  CompanyInfoModel.initPropertyInput(this.company.manufacturers);

        if(!this.company.financialInfos || this.company.financialInfos.length <= 0) {
          this.company.financialInfos = [];
          this.initFinancialData();
        }
      },
      initFinancialData(){
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        let currentMonth = currentDate.getMonth();
        this.company.financialInfos = CompanyInfoModel.initPropertyInput(this.company.financialInfos, [
          { year: currentYear    , month: currentMonth }, 
          { year: currentYear - 1, month: currentMonth }, 
          { year: currentYear - 2, month: currentMonth }
        ]);
      },
      isFinancialRequired(index){
        if(index > 2) return false;
        let ed = new Date(this.company.summary.establishmentDateYear, this.company.summary.establishmentDateMonth, 1);
        let today = new Date();
        let years = ((today - ed) / 1000) / (60 * 60 * 24) / 365.25;
        if( years > 3) return index <= 2;
        if( years > 2) return index <= 1;
        if( years > 1) return index <= 0;
        return false;
      }
    }
  }
</script>